const config = {
  rootProjectId: "root",
  uiBucketName: "gain.c.retter.io",
  appUrl: "https://api.gain.retter.io/",
  cosUrl: "api.gain.retter.io",
  version: "2.1.15",
  captchaKey: "6Lef1ccpAAAAAJ3vhTeZ0DKrAP5EoVCDQdyc22P5",
  stage: "PROD"
}
export default config

